import Vue from 'vue/dist/vue.esm';
import './modal.scss';
import { axiosInstance as axios } from './axios-instance';

document.addEventListener('DOMContentLoaded', () => {
  const vm = new Vue({
    el: '#app',
    data: {
      isNavMenuOpen: false,
      isModalVisible: false,
      userEmail: '',
      isSubscribed: false,
      tagLine: '',
    },
    methods: {
      alertMe: () => {
        alert('Vue function!');
      },
      openNewTab: (url) => {
        window.open(url, "_blank");
      },
      redirectTo: (event, url) => {
        if (['A', 'BUTTON'].includes(event.target.nodeName)) {
          return;
        }
        window.location.href = url;
      },
      closeParent: (event) => {
        const element = event.target;
        const closeTarget = element.closest('.close-target');
        closeTarget.parentElement.removeChild(closeTarget);
      },
      subscribeUserEmail: function() {
        axios.post('/subscribe-email', {
          email: this.userEmail,
        }).then(() => {
          this.userEmail = '';
          this.isSubscribed = true;
        }).catch((res) => {
          console.warn(res)
        });
      },
    }
  })
});
